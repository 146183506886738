<template>
    <div v-html="content">

    </div>
</template>

<script>
    import {PostApi} from '@/api'

    export default {
        name: "CustomerService",
        data() {
            return {
                title: "",
                content: "",
            }
        },
        created() {
            this.loadPageData();
        },
        methods: {
            loadPageData() {
                let payload = {
                    name: "customer_service"
                }
                PostApi.getNewsDetail(payload).then((respData) => {
                    if (respData.c === 0) {
                        this.title = respData.title;
                        this.content = respData.data.content;
                    }
                }).catch((err) => {
                    // ToastUI.clearToast();
                });
            },
        }

    }
</script>

<style scoped>

</style>